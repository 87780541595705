export const RESET_UPLOADED_PAN = "RESET_UPLOADED_PAN";
export const SET_HEADING = "SET_HEADING";
export const LOADING = "LOADING";

export const GET_COUNT = "GET_COUNT";
export const GET_COUNT_SUCCESS = "GET_COUNT_SUCCESS";
export const GET_COUNT_FAILURE = "GET_COUNT_FAILURE";

export const GET_USER_BY_EMP_ID = "GET_USER_BY_EMP_ID";
export const GET_USER_BY_EMP_ID_SUCCESS = "GET_USER_BY_EMP_ID_SUCCESS";
export const GET_USER_BY_EMP_ID_FAILURE = "GET_USER_BY_EMP_ID_FAILURE";

export const POST_ADD_LEAD = "POST_ADD_LEAD";
export const POST_ADD_LEAD_SUCCESS = "POST_ADD_LEAD_SUCCESS";
export const POST_ADD_LEAD_FAILURE = "POST_ADD_LEAD_FAILURE";

export const ADD_DSA_LEAD = "ADD_DSA_LEAD";
export const ADD_DSA_LEAD_SUCCESS = "ADD_DSA_LEAD_SUCCESS";
export const ADD_DSA_LEAD_FAILURE = "ADD_DSA_LEAD_FAILURE";

export const ADD_DEALER_LEAD = "ADD_DEALER_LEAD";
export const ADD_DEALER_LEAD_SUCCESS = "ADD_DEALER_LEAD_SUCCESS";
export const ADD_DEALER_LEAD_FAILURE = "ADD_DEALER_LEAD_FAILURE";

export const CREATE_CONSENT_SUCCESS = "CREATE_CONSENT_SUCCESS";
export const CREATE_CONSENT_FAILURE = "CREATE_CONSENT_FAILURE";

export const DELETE_LEAD = "DELETE_LEAD";
export const DELETE_LEAD_SUCCESS = "DELETE_LEAD_SUCCESS";
export const DELETE_LEAD_FAILURE = "DELETE_LEAD_FAILURE";

export const GET_QDE_ENTITY_LIST = "GET_QDE_ENTITY_LIST";
export const GET_QDE_ENTITY_LIST_SUCCESS = "GET_QDE_ENTITY_LIST_SUCCESS";
export const GET_QDE_ENTITY_LIST_FAILURE = "GET_QDE_ENTITY_LIST_FAILURE";

export const VERIFY_PAN = "VERIFY_PAN";
export const VERIFY_PAN_SUCCESS = "VERIFY_PAN_SUCCESS";
export const VERIFY_PAN_FAILURE = "VERIFY_PAN_FAILURE";

export const UPDATE_LEAD = "UPDATE_LEAD";
export const UPDATE_LEAD_SUCCESS = "UPDATE_LEAD_SUCCESS";
export const UPDATE_LEAD_FAILURE = "UPDATE_LEAD_FAILURE";
export const SAVE_PANGST_SUCCESS = "SAVE_PANGST_SUCCESS";
export const SAVE_PANGST_FAILURE = "SAVE_PANGST_FAILURE";

export const UPLOAD_DOC_FAILURE = "UPLOAD_DOC_FAILURE";
export const UPLOAD_DOC_SUCCESS = "UPLOAD_DOC_SUCCESS";

export const UPLOAD_DOC_FB_FAILURE = "UPLOAD_DOC_FB_FAILURE";
export const UPLOAD_DOC_FB_SUCCESS = "UPLOAD_DOC_FB_SUCCESS";

export const SAVE_LEAD_DETAILS = "SAVE_LEAD_DETAILS";

export const SAVE_SCHEME_SUCCESS = "SAVE_SCHEME_SUCCESS";
export const SAVE_SCHEME_FAILURE = "SAVE_SCHEME_FAILURE";

export const SAVE_UPDATE_BUSINESS_DETAILS = "SAVE_UPDATE_BUSINESS_DETAILS";
export const SAVE_UPDATE_BUSINESS_DETAILS_SUCCESS =
  "SAVE_UPDATE_BUSINESS_DETAILS_SUCCESS";
export const SAVE_UPDATE_BUSINESS_DETAILS_FAILURE =
  "SAVE_UPDATE_BUSINESS_DETAILS_FAILURE";

export const VERIFY_CONCENT = "VERIFY_CONCENT";
export const VERIFY_CONCENT_SUCCESS = "VERIFY_CONCENT_SUCCESS";
export const VERIFY_CONCENT_FAILURE = "VERIFY_CONCENT_FAILURE";

export const GET_DEALERLIST_SUCCESS = "GET_DEALERLIST_SUCCESS";
export const GET_DEALERLIST_FAILURE = "GET_DEALERLIST_FAILURE";

export const GET_DSALIST_SUCCESS = "GET_DSALIST_SUCCESS";
export const GET_DSALIST_FAILURE = "GET_DSALIST_FAILURE";

export const GET_LEAD_DETAILS = "GET_LEAD_DETAILS";
export const GET_LEAD_DETAILS_SUCCESS = "GET_LEAD_DETAILS_SUCCESS";
export const GET_LEAD_DETAILS_FAILURE = "GET_LEAD_DETAILS_FAILURE";

export const RESET_LEADS = "RESET_LEADS";

export const GET_LOAN_SUMMARY = "GET_LOAN_SUMMARY";
export const GET_LOAN_SUMMARY_SUCCESS = "GET_LOAN_SUMMARY_SUCCESS";
export const GET_LOAN_SUMMARY_FAILURE = "GET_LOAN_SUMMARY_FAILURE";

export const RESET_QDE = "RESET_QDE";

export const SAVE_FAMILY_REF = "SAVE_FAMILY_REF";
export const SAVE_FAMILY_REF_SUCCESS = "SAVE_FAMILY_REF_SUCCESS";
export const SAVE_FAMILY_REF_FAILURE = "SAVE_FAMILY_REF_FAILURE";

export const SAVE_NONFAMILY_REF = "SAVE_NONFAMILY_REF";
export const SAVE_NONFAMILY_REF_SUCCESS = "SAVE_NONFAMILY_REF_SUCCESS";
export const SAVE_NONFAMILY_REF_FAILURE = "SAVE_NONFAMILY_REF_FAILURE";

export const GET_REFERENCE_DETAILS = "GET_REFERENCE_DETAILS";
export const GET_REFERENCE_DETAILS_SUCCESS = "GET_REFERENCE_DETAILS_SUCCESS";
export const GET_REFERENCE_DETAILS_FAILURE = "GET_REFERENCE_DETAILS_FAILURE";

export const ADD_COAPPLICANT_GUARANTOR = "ADD_COAPPLICANT_GUARANTOR";
export const ADD_COAPPLICANT_GUARANTOR_SUCCESS =
  "ADD_COAPPLICANT_GUARANTOR_SUCCESS";
export const ADD_COAPPLICANT_GUARANTOR_FAILURE =
  "ADD_COAPPLICANT_GUARANTOR_FAILURE";

export const GET_COAPPLICANT_GUARANTOR_DETAILS_BY_COAPPLICANTUNIQUEID =
  "GET_COAPPLICANT_GUARANTOR_DETAILS_BY_COAPPLICANTUNIQUEID";

export const GET_COAPPLICANT_GUARANTOR_DETAILS_BY_COAPPLICANTUNIQUEID_SUCCESS =
  "GET_COAPPLICANT_GUARANTOR_DETAILS_BY_COAPPLICANTUNIQUEID_SUCCESS";

export const GET_COAPPLICANT_GUARANTOR_DETAILS_BY_COAPPLICANTUNIQUEID_FAILURE =
  "GET_COAPPLICANT_GUARANTOR_DETAILS_BY_COAPPLICANTUNIQUEID_FAILURE";

export const DELETE_COAPPLICANT_GUARANTOR = "DELETE_COAPPLICANT_GUARANTOR";
export const DELETE_COAPPLICANT_GUARANTOR_SUCCESS =
  "DELETE_COAPPLICANT_GUARANTOR_SUCCESS";
export const DELETE_COAPPLICANT_GUARANTOR_FAILURE =
  "DELETE_COAPPLICANT_GUARANTOR_FAILURE";

export const CREATE_CONSENT_COAPPLICANT_GUARANTOR =
  "CREATE_CONSENT_COAPPLICANT_GUARANTOR";
export const CREATE_CONSENT_COAPPLICANT_GUARANTOR_SUCCESS =
  "CREATE_CONSENT_COAPPLICANT_GUARANTOR_SUCCESS";
export const CREATE_CONSENT_COAPPLICANT_GUARANTOR_FAILURE =
  "CREATE_CONSENT_COAPPLICANT_GUARANTOR_FAILURE";

export const VERIFY_COAPPLICANT_GUARANTOR_CONCENT_SUCCESS =
  "VERIFY_COAPPLICANT_GUARANTOR_CONCENT_SUCCESS";

export const VERIFY_COAPPLICANT_GUARANTOR_CONCENT_FAILURE =
  "VERIFY_COAPPLICANT_GUARANTOR_CONCENT_FAILURE";

export const VERIFY_COAPPLICANT_GUARANTOR_CONCENT =
  "VERIFY_COAPPLICANT_GUARANTOR_CONCENT";

export const ADD_EMPLOYEE_DETAILS = "ADD_EMPLOYEE_DETAILS";
export const ADD_EMPLOYEE_DETAILS_SUCCESS = "ADD_EMPLOYEE_DETAILS_SUCCESS";
export const ADD_EMPLOYEE_DETAILS_FAILURE = "ADD_EMPLOYEE_DETAILS_FAILURE";

export const GET_PINCODE = "GET_PINCODE";
export const GET_PINCODE_SUCCESS = "GET_PINCODE_SUCCESS";
export const GET_PINCODE_FAILURE = "GET_PINCODE_FAILURE";

export const ADD_CONTACT_DETAIL = "ADD_CONTACT_DETAIL";
export const ADD_CONTACT_DETAIL_SUCCESS = "ADD_CONTACT_DETAIL_SUCCESS";
export const ADD_CONTACT_DETAIL_FAILURE = "ADD_CONTACT_DETAIL_FAILURE";

export const GET_ALT_CONTACT_DETAIL = "GET_CONTACT_DETAIL";
export const GET_ALT_CONTACT_DETAIL_SUCCESS = "GET_CONTACT_DETAIL_SUCCESS";
export const GET_ALT_CONTACT_DETAIL_FAILURE = "GET_CONTACT_DETAIL_FAILURE";

export const UPLOAD_POI_DOC = "UPLOAD_POI_DOC";
export const UPLOAD_POI_DOC_SUCCESS = "UPLOAD_POI_DOC_SUCCESS";
export const UPLOAD_POI_DOC_FAILURE = "UPLOAD_POI_DOC_FAILURE";

export const GET_QDE_DETAILS = "GET_QDE_DETAILS";
export const GET_QDE_DETAILS_SUCCESS = "GET_QDE_DETAILS_SUCCESS";
export const GET_QDE_DETAILS_FAILURE = "GET_QDE_DETAILS_FAILURE";

export const ADDRESS_DETAILS_QDE = "ADDRESS_DETAILS_QDE";
export const ADDRESS_DETAILS_QDE_SUCCESS = "ADDRESS_DETAILS_QDE_SUCCESS";
export const ADDRESS_DETAILS_QDE_FAILURE = "ADDRESS_DETAILS_QDE_FAILURE";

export const GET_RESIDENT_TYPE_SUCCESS = "GET_RESIDENT_TYPE_SUCCESS";
export const GET_RESIDENT_TYPE_FAILURE = "GET_RESIDENT_TYPE_FAILURE";

export const VERIFY_DL_SUCCESS = "VERIFY_DL_SUCCESS";
export const VERIFY_DL_FAILURE = "VERIFY_DL_FAILURE";

export const UPLOAD_POA_DOC = "UPLOAD_POA_DOC";
export const UPLOAD_POA_DOC_SUCCESS = "UPLOAD_POA_DOC_SUCCESS";
export const UPLOAD_POA_DOC_FAILURE = "UPLOAD_POA_DOC_FAILURE";

export const SAVE_UTILITY_BILL = "SAVE_UTILITY_BILL";
export const SAVE_UTILITY_BILL_SUCCESS = "SAVE_UTILITY_BILL_SUCCESS";
export const SAVE_UTILITY_BILL_FAILURE = "SAVE_UTILITY_BILL_FAILURE";

export const SAVE_POI = "SAVE_POI";
export const SAVE_POI_SUCCESS = "SAVE_POI_SUCCESS";
export const SAVE_POI_FAILURE = "SAVE_POI_FAILURE";

export const VERIFY_VOTER = "VERIFY_VOTER";
export const VERIFY_VOTER_SUCCESS = "VERIFY_VOTER_SUCCESS";
export const VERIFY_VOTER_FAILURE = "VERIFY_VOTER_FAILURE";

export const GET_QDE_LOAN = " GET_QDE_LOAN";
export const GET_QDE_LOAN_SUCCESS = " GET_QDE_LOAN_SUCCESS";
export const GET_QDE_LOAN_FAILURE = " GET_QDE_LOAN_FAILURE";

export const GET_DDE_DETAILS = "GET_DDE_DETAILS";
export const GET_DDE_DETAILS_SUCCESS = "GET_DDE_DETAILS_SUCCESS";
export const GET_DDE_DETAILS_FAILURE = "GET_DDE_DETAILS_FAILURE";

export const GET_BANK_DETAILS = "GET_BANK_DETAILS";
export const GET_BANK_DETAILS_SUCCESS = "GET_BANK_DETAILS_SUCCESS";
export const GET_BANK_DETAILS_FAILURE = "GET_BANK_DETAILS_FAILURE";

export const REFRESH_BANK_DETAILS = "REFRESH_BANK_DETAILS";
export const REFRESH_BANK_DETAILS_SUCCESS = "REFRESH_BANK_DETAILS_SUCCESS";
export const REFRESH_BANK_DETAILS_FAILURE = "REFRESH_BANK_DETAILS_FAILURE";

export const SAVE_BANK_DETAILS = "SAVE_BANK_DETAILS";
export const SAVE_BANK_DETAILS_SUCCESS = "SAVE_BANK_DETAILS_SUCCESS";
export const SAVE_BANK_DETAILS_FAILURE = "SAVE_BANK_DETAILS_FAILURE";

export const DELETE_SALARY_SLIP = "DELETE_SALARY_SLIP";
export const DELETE_SALARY_SLIP_SUCCESS = "DELETE_SALARY_SLIP_SUCCESS";
export const DELETE_SALARY_SLIP_FAILURE = "DELETE_SALARY_SLIP_FAILURE";

export const GET_QDE_SCHEME = " GET_QDE_SCHEME";
export const GET_QDE_SCHEME_SUCCESS = " GET_QDE_SCHEME_SUCCESS";
export const GET_QDE_SCHEME_FAILURE = " GET_QDE_SCHEME_FAILURE";
export const RESET_QDE_SCHEMES_SUCCESS = "RESET_QDE_SCHEMES_SUCCESS";

export const UPLOAD_ITR_DOC = "UPLOAD_ITR_DOC";
export const UPLOAD_ITR_DOC_SUCCESS = "UPLOAD_ITR_DOC_SUCCESS";
export const UPLOAD_ITR_DOC_FAILURE = "UPLOAD_ITR_DOC_FAILURE";

export const SEND_ITR_LINK = "SEND_ITR_LINK";
export const SEND_ITR_LINK_SUCCESS = "SEND_ITR_LINK_SUCCESS";
export const SEND_ITR_LINK_FAILURE = "SEND_ITR_LINK_FAILURE";

export const VERIFY_ITR_CREDS = "VERIFY_ITR_CREDS";
export const VERIFY_ITR_CREDS_SUCCESS = "VERIFY_ITR_CREDS_SUCCESS";
export const VERIFY_ITR_CREDS_FAILURE = "VERIFY_ITR_CREDS_FAILURE";
export const SAVE_LOAN_DETAILS = "SAVE_LOAN_DETAILS";
export const SAVE_LOAN_DETAILS_SUCCESS = "SAVE_LOAN_DETAILS_SUCCESS";
export const SAVE_LOAN_DETAILS_FAILURE = "SAVE_LOAN_DETAILS_FAILURE";

export const DELETE_DOCUMENT = "DELETE_DOCUMENT";
export const DELETE_DOCUMENT_SUCCESS = "DELETE_DOCUMENT_SUCCESS";
export const DELETE_DOCUMENT_FAILURE = "DELETE_DOCUMENT_FAILURE";

export const KYC_OTHER_DOC = "KYC_OTHER_DOC";
export const KYC_OTHER_DOC_SUCCESS = "KYC_OTHER_DOC_SUCCESS";
export const KYC_OTHER_DOC_FAILURE = "KYC_OTHER_DOC_FAILURE";

export const DELETE_ITR_UPLOAD = "DELETE_ITR_UPLOAD";
export const DELETE_ITR_UPLOAD_SUCCESS = "DELETE_ITR_UPLOAD_SUCCESS";
export const DELETE_ITR_UPLOAD_FAILURE = "DELETE_ITR_UPLOAD_FAILURE";

export const REQUEST_ESERVICE = "REQUEST_ESERVICE";
export const REQUEST_ESERVICE_SUCCESS = "REQUEST_ESERVICE_SUCCESS";
export const REQUEST_ESERVICE_FAILURE = "REQUEST_ESERVICE_FAILURE";

export const GET_ESERVICE = "GET_ESERVICE";
export const GET_ESERVICE_SUCCESS = "GET_ESERVICE_SUCCESS";
export const GET_ESERVICE_FAILURE = "GET_ESERVICE_FAILURE";

export const UPLOAD_LOAN_AGREEMENT = "UPLOAD_LOAN_AGREEMENT";
export const UPLOAD_LOAN_AGREEMENT_SUCCESS = "UPLOAD_LOAN_AGREEMENT_SUCCESS";
export const UPLOAD_LOAN_AGREEMENT_FAILURE = "UPLOAD_LOAN_AGREEMENT_FAILURE";

export const DELETE_UPLOADED_LOAN_AGREEMENT = "DELETE_UPLOADED_LOAN_AGREEMENT";
export const DELETE_UPLOADED_LOAN_AGREEMENT_SUCCESS =
  "DELETE_UPLOADED_LOAN_AGREEMENT_SUCCESS";
export const DELETE_UPLOADED_LOAN_AGREEMENT_FAILURE =
  "DELETE_UPLOADED_LOAN_AGREEMENT_FAILURE";

export const SUBMIT_TO_CREDIT = "SUBMIT_TO_CREDIT";
export const SUBMIT_TO_CREDIT_SUCCESS = "SUBMIT_TO_CREDIT_SUCCESS";
export const SUBMIT_TO_CREDIT_FAILURE = "SUBMIT_TO_CREDIT_FAILURE";

export const ADDRESS_DETAILS_PERMANENT = "ADDRESS_DETAILS_PERMANENT";
export const ADDRESS_DETAILS_PERMANENT_SUCCESS =
  "ADDRESS_DETAILS_PERMANENT_SUCCESS";
export const ADDRESS_DETAILS_PERMANENT_FAILURE =
  "ADDRESS_DETAILS_PERMANENT_FAILURE";

export const GET_UTILITY_DETAILS = "GET_UTILITY_DETAILS";
export const GET_UTILITY_DETAILS_SUCCESS = "GET_UTILITY_DETAILS_SUCCESS";
export const GET_UTILITY_DETAILS_FAILURE = "GET_UTILITY_DETAILS_FAILURE";

export const DELETE_UTILITY_DOCUMENT = "DELETE_UTILITY_DOCUMENT";
export const DELETE_UTILITY_DOCUMENT_SUCCESS =
  "DELETE_UTILITY_DOCUMENT_SUCCESS";
export const DELETE_UTILITY_DOCUMENT_FAILURE =
  "DELETE_UTILITY_DOCUMENT_FAILURE";

export const RESET_DDE = "RESET_DDE";

export const DOWNLOAD_LOAN_AGREEMENT = "DOWNLOAD_LOAN_AGREEMENT";
export const DOWNLOAD_LOAN_AGREEMENT_SUCCESS =
  "DOWNLOAD_LOAN_AGREEMENT_SUCCESS";
export const DOWNLOAD_LOAN_AGREEMENT_FAILURE =
  "DOWNLOAD_LOAN_AGREEMENT_FAILURE";

export const RESET_LOAN_SUMMARY = "RESET_LOAN_SUMMARY";

export const GET_BRANCH = "GET_BRANCH";
export const GET_BRANCH_SUCCESS = "GET_BRANCH_SUCCESS";
export const GET_BRANCH_FAILURE = "GET_BRANCH_FAILURE";
//--------------PERSONAL DETAILS------------------
export const SAVE_PERSONAL_DETAILS_SUCCESS = "SAVE_PERSONAL_DETAILS_SUCCESS";
export const SAVE_PERSONAL_DETAILS_FAILURE = "SAVE_PERSONAL_DETAILS_FAILURE";

export const UPLOAD_SELFIE = "UPLOAD_SELFIE";
export const UPLOAD_SELFIE_SUCCESS = "UPLOAD_SELFIE_SUCCESS";
export const UPLOAD_SELFIE_FAILURE = "UPLOAD_SELFIE_FAILURE";

export const UPLOAD_SELFIE_FB = "UPLOAD_SELFIE_FB";
export const UPLOAD_SELFIE_FB_SUCCESS = "UPLOAD_SELFIE_FB_SUCCESS";
export const UPLOAD_SELFIE_FB_FAILURE = "UPLOAD_SELFIE_FB_FAILURE";

export const DELETE_UPLOADED_SELFIE = "DELETE_UPLOADED_SELFIE";
export const DELETE_UPLOADED_SELFIE_SUCCESS = "DELETE_UPLOADED_SELFIE_SUCCESS";
export const DELETE_UPLOADED_SELFIE_FAILURE = "DELETE_UPLOADED_SELFIE_FAILURE";

export const DELETE_UPLOADED_SELFIE_FB = "DELETE_UPLOADED_SELFIE_FB";
export const DELETE_UPLOADED_SELFIE_FB_SUCCESS = "DELETE_UPLOADED_SELFIE_FB_SUCCESS";
export const DELETE_UPLOADED_SELFIE_FB_FAILURE = "DELETE_UPLOADED_SELFIE_FB_FAILURE";

export const GET_MAX_AMT = "GET_MAX_AMT";
export const GET_MAX_AMT_SUCCESS = "GET_MAX_AMT_SUCCESS";
export const GET_MAX_AMT_FAILURE = "GET_MAX_AMT_FAILURE";

export const GET_LOGIN_MODULE = "GET_LOGIN_MODULE";
export const GET_LOGIN_MODULE_SUCCESS = "GET_LOGIN_MODULE_SUCCESS";
export const GET_LOGIN_MODULE_FAILURE = "GET_LOGIN_MODULE_FAILURE";

export const RESET_DELETE_LEAD = "RESET_DELETE_LEAD";
export const SET_LEAD_LIST_TYPE = "SET_LEAD_LIST_TYPE";

//------------------------Repayment----------------------------------------
export const SAVE_REPAYMENT_SUCCESS = "SAVE_REPAYMENT_SUCCESS";
export const SAVE_REPAYMENT_FAILURE = "SAVE_REPAYMENT_FAILURE";

export const SAVE_COMMENT_SUCCESS = "SAVE_COMMENT_SUCCESS";
export const SAVE_COMMENT_FAILURE = "SAVE_COMMENT_FAILURE";

export const GET_REPAYMENT_SUCCESS = "GET_REPAYMENT_SUCCESS";
export const GET_REPAYMENT_FAILURE = "GET_REPAYMENT_FAILURE";

export const GET_COMMENT_SUCCESS = "GET_COMMENT_SUCCESS";
export const GET_COMMENT_FAILURE = "GET_COMMENT_FAILURE";
//------------------------------------------------------------------

export const UPLOAD_DISBS = "UPLOAD_DISBS";
export const UPLOAD_DISBS_SUCCESS = "UPLOAD_DISBS_SUCCESS";
export const UPLOAD_DISBS_FAILURE = "UPLOAD_DISBS_FAILURE";

export const GET_DISBS = "GET_DISBS";
export const GET_DISBS_SUCCESS = "GET_DISBS_SUCCESS";
export const GET_DISBS_FAILURE = "GET_DISBS_FAILURE";

export const DELETE_DISBS = "DELETE_DISBS";
export const DELETE_DISBS_SUCCESS = "DELETE_DISBS_SUCCESS";
export const DELETE_DISBS_FAILURE = "DELETE_DISBS_FAILURE";

export const SAVE_PAN_GET_GST_SUCCESS = "SAVE_PAN_GET_GST_SUCCESS";
export const SAVE_PAN_GET_GST_FAILURE = "SAVE_PAN_GET_GST_FAILURE";

export const GET_GST_ADDRESS_SUCCESS = "GET_GST_ADDRESS_SUCCESS";
export const GET_GST_ADDRESS_FAILURE = "GET_GST_ADDRESS_FAILURE";

//--------------------------Finbit----------------------------------------
export const GET_FINBIT_URL_SUCCESS = "GET_FINBIT_URL_SUCCESS";
export const GET_FINBIT_URL_FAILURE = "GET_FINBIT_URL_FAILURE";

// -------------------SCHEME-CODE------------------/

export const GET_SCHEME_CODE = "GET_SCHEME_CODE";
export const GET_SCHEME_CODE_SUCCESS = "GET_SCHEME_CODE_SUCCESS";
export const GET_SCHEME_CODE_FAILURE = "GET_SCHEME_CODE_FAILURE";

export const GET_SCHEME_DETAILS = "GET_SCHEME_DETAILS";
export const GET_SCHEME_DETAILS_SUCCESS = "GET_SCHEME_DETAILS_SUCCESS";
export const GET_SCHEME_DETAILS_FAILURE = "GET_SCHEME_DETAILS_FAILURE";

//--------------------REPAYMENT NACH---------------------/
export const SAVE_ENACH_SUCCESS = "SAVE_ENACH_SUCCESS";
export const SAVE_ENACH_FALIURE = "SAVE_ENACH_FALIURE";

// ------------penny <drop----------------- />
export const PENNY_DROP_SUCCESS = "PENNY_DROP_SUCCESS";
export const PENNY_DROP_FAILURE = "PENNY_DROP_FAILURE";

// -----------gupshup-------------/>
 export const GET_USER_LIST_SUCCESS = "GET_USER_LIST_SUCCESS";
 export const GET_USER_LIST_FAILURE = "GET_USER_LIST_FAILURE";

 //-----------Enach Link-----------
export const GET_CODE_AND_ID_SUCCESS = "GET_CODE_AND_ID_SUCCESS";
export const GET_CODE_AND_ID_FAILURE = "GET_CODE_AND_ID_FAILURE";
